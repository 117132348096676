<template>

  <div>
    <section class="selfcare-title d-flex">
      <div class="px-2">
        <a @click="goBack()" class="custom-link text-light">
          <i class="bi bi-arrow-left"></i>
        </a>
      </div>
      <div class="text-yellow width-available text-center">
        <strong>
          RESPONSIBLE GAMBLING
        </strong>
      </div>
    </section>


    <div style="color: white; padding: 5px">
      <p>
        <strong>
          Please be aware that this is a real-money gambling app. You must be 18 years of age or older to
          register or play at qwerty.com.
        </strong>
      </p>
      <h5> Responsible Gambling</h5>
      <p>
        qwerty's vision is to create a safe and conducive environment where players can engage in
        betting and gambling activities while also enjoying their favourite sporting events. qwerty further
        urges its customers to treat betting and related gambling activities as an enjoyable and fun
        activity and not as a source of livelihood.
      </p>

      <div>
        <h5>What are the signs of irresponsible/uncontrolled gambling?</h5>
        <ol>
          <li> Betting with more money than you can afford to lose.</li>
          <li> Chasing your losses. This simply means placing subsequent bets with the sole intention of recovering
            money lost on previous bets.</li>
          <li> Treating gambling activities as a source of income.</li>
          <li> Spending a lot of time on betting sites making your predictions.</li>
          <li> Betting when one is not in their normal psychological state.</li>
          <li> Sacrificing time with friends, family or colleagues in order to engage in betting activities.</li>
          <li> Prioritizing gambling over other outside activities and engagements.</li>
        </ol>
      </div>


      <h5>How can I become a responsible gambler?</h5>
      <p>To become a disciplined and responsible gambler, qwerty urges you to do, among other tips
        you may find elsewhere, the following;
      </p>

      <ol>
        <li> Always bet with money you can afford to lose.</li>
        <li>Do not chase your losses.</li>
        <li>Remember that betting, as all other gambling activities, is a fun and enjoyable activity and not a means by
          which to earn a living.</li>
        <li>Spend as little time as possible on betting sites. Taking breaks from time to time is highly recommended.
        </li>
        <li> Do not engage in betting activities while overly excited, angry, sad, depressed etc. In addition, do not
          place your bets while under the impairment of alcohol and drugs.</li>
        <li> Be open to your trusted friends and family about your betting activities and they will know if you need
          help.</li>
        <li> Take time off of gambling. Venture outside and engage in essential socioeconomic activities.</li>
      </ol>

      <p>
        qwerty has also taken great caution and measures to ensure that only individuals of eighteen years and above
        can engage in the betting and gambling services offered through their platform;
      </p>

      <ol>
        <li> You must tick the box indicating you are of eighteen years and above at the time of registration.</li>
        <li> Adults are encouraged to protect their devices with passwords so as to prevent exposure of betting
          activities to minors.</li>
        <li> Adults are also encouraged to make use of website filtering and general blocking softwares such as Net
          Nanny to limit exposure of potentially harmful web content (gambling included) to minors.</li>
      </ol>


    </div>


    <bottom-navigation active-bets="0" page="login"></bottom-navigation>

  </div>

</template>

<script>

import BottomNavigation from "@/components/BottomNavigation";

export default {
  name: 'Responsible',
  components: {

    BottomNavigation
  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage", "responsible");
    this.reloadProfile();

  }

}
</script>